section#socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25vh;

    overflow: hidden;
}

section#socials > div {
    padding: 10px;
    width: 100%;
    background-color: #181818;
}

section#socials h1 {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(25px + 1.5vw);
    margin: 0 20px 20px 20px;
    font-weight: lighter;
}

.placeholder-grid {
    height: 0px !important;
    pointer-events: none !important;
}

.placeholder-grid,
section#socials div#grid {
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
    grid-gap: 20px 10px;
    padding: 0 10px 0 10px;

    grid-template-columns: repeat(auto-fit, minmax(85px, 1fr));
}

section#socials div#grid > *:last-child {
    width: 100%;
    max-width: none;
    aspect-ratio: 0;
}

section#socials div#grid > * {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 85px;
    aspect-ratio: 1;
}

@media (min-width: 330px) and (max-width: 430px) {
    .placeholder-grid,
    section#socials div#grid {
        grid-gap: 0px 0px;
        padding: 0;

        grid-template-columns: repeat(auto-fit, minmax(70px, 0.8fr));
    }
}
