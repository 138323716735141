section#projects {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15vh;
}

section#projects > div {
    padding: 20px;
    width: 100%;
    /* background-color: #191919; */
}

section#projects h1 {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(25px + 1.5vw);
    margin: 0 20px 20px 20px;
    font-weight: lighter;
}

div#projects-grid {
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: center;
    grid-gap: 20px 15px;

    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

@media screen and (max-width: 768px) {
    div#projects-grid {
        grid-template-columns: none;
    }
}
