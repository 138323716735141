section#skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15vh;
}

section#skills > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    /* background-color: #191919; */
    border-radius: 10px;
}

section#skills h1 {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(25px + 1.5vw);
    margin: 0 20px 20px 20px;
    font-weight: lighter;
}

section#skills h2 {
    color: rgba(255, 255, 255, 0.3);
    font-size: calc(15px + 0.6vw);
    margin: 20px;
    font-weight: lighter;
}

div.skills-grid {
    display: grid;
    width: 100%;
    justify-content: center;
    justify-items: start;
    grid-gap: 30px;

    grid-template-columns: repeat(
        3,
        minmax(100px, min(700px, calc(100px + 20vw)))
    );
}

@media screen and (max-width: 950px) {
    div.skills-grid {
        grid-template-columns: repeat(auto-fill, minmax(calc(47%), 0fr));
    }
}

@media screen and (max-width: 550px) {
    div.skills-grid {
        grid-template-columns: repeat(auto-fill, minmax(calc(100%), 0fr));
    }
}
