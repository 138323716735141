.box {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;

    /* background-color: #191919; */
    background-color: #191919;
    /* width: 250px; */
    width: 100%;
    height: 500px;
    overflow: hidden;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
}

.box > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    width: 100%;

    transition: all 0.3s;
}

.box .background_image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    background-position: center;
    background-size: calc(125vh + 25vw);
    background-attachment: local;
    background-repeat: no-repeat;

    user-select: none;
}

.box > a:hover {
    height: 45%;
}

.box > a:hover ~ .box_text {
    opacity: 0.4;
    overflow: hidden;
}

.box .background_image img {
    height: 100%;
    user-select: none;
}

.box > div {
    padding: 10px;
}

.box > div h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);
    font-size: calc(14px + 0.3vw);
    letter-spacing: 1px;
}

.box > div h2 img::selection {
    background-color: transparent !important;
}

.box > div p {
    color: rgba(255, 255, 255, 0.6);
    font-size: calc(13px + 0.1vw);
}

.box .box_text {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: 100%;
    height: 60%;
    padding: 15px;
    color: rgba(255, 255, 255, 0.5);
    overflow: hidden auto;

    transition: all 0.3s;
}

@media (hover: none) and (pointer: coarse) {
    .box .box_text {
        overflow: hidden;
    }
}

.box .box_text:active,
.box .box_text:hover,
.box .box_text::selection {
    overflow: hidden auto;
}

.box .box_text p {
    height: 100%;
}

.box .box_text small {
    margin: 0 10px;
}

.box .box_text div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    margin: 5px;
}

.box .box_specification img {
    width: 15px;
    height: 15px;
}

.box .box_specification img::selection {
    background-color: transparent !important;
}

.box .box_title {
    display: flex;
    width: 100%;
    text-decoration: none;
}

.box .box_specification img {
    margin: 0 5px;
}

.box_specification {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 75px;
    background-color: #151515;
}
