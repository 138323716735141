/* Brackets */
.brackets {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    color: rgba(255, 255, 255, 0.4) !important;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
}

.brackets:not(h1):hover {
    color: rgba(255, 255, 255, 0.9) !important;
    cursor: pointer;
}

.brackets.hover:hover {
    color: rgba(255, 255, 255, 0.9) !important;
}

.brackets.hover {
    color: rgba(255, 255, 255, 0.75) !important;
}

.brackets::before,
.brackets::after,
.brackets {
    -webkit-transition: -webkit-transform 0.4s, color 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.4s, color 0.3s, opacity 0.3s;
    transition: transform 0.4s, color 0.3s, opacity 0.3s;
}

.brackets::before,
.brackets::after {
    color: rgba(255, 255, 255, 0);
    opacity: 0;
}

.brackets::before {
    content: "{";
    margin-right: 10px;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
}

.brackets::after {
    content: "}";
    margin-left: 10px;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
}

.brackets:hover::after,
.brackets:hover::before {
    color: rgba(255, 255, 255, 0.9);
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.brackets.hover:hover::after,
.brackets.hover:hover::before {
    color: rgba(255, 255, 255, 0.9);
    opacity: 1;
}

.brackets.hover::after,
.brackets.hover::before {
    color: rgba(255, 255, 255, 0.75);
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

/* Underline */
.underline {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    color: rgba(255, 255, 255, 0.4) !important;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 1);
    padding: 0 4vw;
}

.underline:hover {
    color: rgba(255, 255, 255, 0.75) !important;
}

.underline::after,
.underline {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: 0.2s all linear;
}

.underline::after {
    position: absolute;
    content: "";
    background-color: rgba(255, 255, 255, 0.75);

    width: calc(20px + 40%);
    height: 1px;
    bottom: -20%;

    transform: scale(0, 1);
    transition: all 0.2s linear;
}

.underline:hover::after {
    transform: scale(1, 1);
    transition: all 0.2s ease;
}
