:root {
    --background-color: #202020;
    --text-color: #fff;
    --text-color-muted: rgba(255, 255, 255, 0.8);
    --text-color-secondary: rgba(255, 255, 255, 0.9);
    --border-color: rgba(255, 255, 255, 0.8);
    --padding-horizontal: 5vw;
}

.app-privacy-policy body {
    margin: 0;
    min-height: 100%;
    width: 100%;
    background-color: var(--background-color);
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

.app-privacy-policy .privacy-policy {
    color: var(--text-color);
    padding: var(--padding-horizontal);
    margin-top: 50px;
    border-radius: 8px;
}

.app-privacy-policy .privacy-policy h2 {
    font-weight: bold;
    border-bottom: 1px solid var(--border-color);
    color: var(--text-color-secondary);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.app-privacy-policy .privacy-policy h3,
.app-privacy-policy .privacy-policy ul,
.app-privacy-policy .privacy-policy p {
    margin-top: 30px;
    color: var(--text-color-muted);
}

.app-privacy-policy .privacy-policy ul {
    list-style-type: square;
    padding-left: 20px;
    margin-bottom: 20px;
}

.app-privacy-policy .privacy-policy a {
    color: var(--text-color-secondary);
    text-decoration: underline;
}

.app-privacy-policy .privacy-policy a:hover {
    opacity: 0.8;
}

.app-privacy-policy .brackets,
.app-privacy-policy ul,
.app-privacy-policy button {
    padding: 0;
}

.app-privacy-policy button {
    padding: 0 var(--padding-horizontal);
}

.app-privacy-policy nav div>button {
    padding: 0;
}
