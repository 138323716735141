footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #101010;
    padding: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-weight: lighter;
    font-size: 12px;
}

footer div {
    display: flex;
}

footer div p {
    margin: 0 10px 0 10px;
}

footer div p a {
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

footer div p a:hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
    footer {
        flex-direction: column;
    }
}

@media screen and (max-width: 384px) {
    footer {
        font-size: 10px;
    }
}
