.skill {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: #191919;
    color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    width: calc(100px + 20vw);
    height: calc(100px + 25vh + 2vw);
    max-width: 700px;
    max-height: 500px;

    position: relative;

    transition: all 0.2s ease-in-out;
}

.skill:hover {
    transform: scale(1.05) !important;
    transition: all 0.2s ease-in-out;
    background: transparent;
}

.skill-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    pointer-events: none;
}

.skill h1 {
    width: 100%;
    text-align: center;
    font-size: 1.5rem !important;
    margin: 10px !important;
    margin-top: 20px !important;
    color: #fff !important;
    opacity: 0.9;
    font-weight: 400 !important;
}

.skill p {
    font-size: 1rem !important;
    text-align: center;
    margin: 10px !important;
    margin-top: 0 !important;
    width: 80%;
    opacity: 0.8;
    font-weight: 300 !important;
    letter-spacing: 0.6px;
}

.skill img {
    opacity: 0.8;
}

.skill img::selection {
    background-color: transparent !important;
}

@media screen and (max-width: 950px) {
    .skill {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .skill {
        height: calc(150px + 27vh);
    }
}

@media screen and (max-width: 550px) {
    .skill {
        width: 100%;
    }
}

@media screen and (max-width: 300px) {
    .skill h1 {
        font-size: 1rem !important;
    }

    .skill p {
        font-size: 0.755rem !important;
    }
}
