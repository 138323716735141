.social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 0.5s;
    user-select: none;
}

.social:hover img {
    opacity: 0.95;
    transform: translateY(0);
}

.social:hover p {
    opacity: 1;
    transform: translateY(-5px);
}

.social p {
    color: rgba(255, 255, 255, 0.4);
    margin: 0;
    margin-top: 10px;
    font-size: 15px;
    opacity: 0;
    transition: all 0.5s;
    transform: translateY(15px);
}

.social img {
    width: 35px;
    opacity: 0.6;
    transform: translateY(15px);
    transition: all 0.5s;
}

.social.patreon {
    flex-direction: row;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    width: 100%;
}

.social.patreon img {
    opacity: 1;
    margin: 0 10px;
    transform: none;
}

.social.patreon p {
    opacity: 0.8;
    margin: 0;
    transform: none;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    background: none;
    transition: all 0.5s ease-in-out;
    transition: color 0.4s ease-in-out;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.social.patreon:hover p,
.social.patreon:focus p {
    opacity: 1;
    animation: gold-shine 2s linear infinite;
    background: linear-gradient(
        90deg,
        #8b7500,
        #daa520,
        #ffcc00,
        #ffd700,
        #b8860b
    );
    background-size: 200%;
    -webkit-background-clip: text;
    background-clip: text;
    color: rgba(0, 0, 0, 0);
}

@keyframes gold-shine {
    0% {
        background-position: 0%;
    }
    100% {
        background-position: 200%;
    }
}

@media screen and (max-width: 768px) {
    .social img {
        width: 30px;
    }

    .social p {
        font-size: 12px;
    }
}
