* {
    box-sizing: border-box;
}

*::selection {
    background-color: #fff !important;
    color: #101010 !important;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    zoom: 100%;
}

body {
    margin: 0px;
    min-height: 100%;
    width: 100%;
    background-color: #202020ff;
    font-family: Arial, Helvetica, sans-serif;
    overflow-x: hidden;
}

.external_link {
    filter: brightness(0.7) sepia(1) hue-rotate(190deg) saturate(2);
}

canvas {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.5s, height 0.6s, top 0.5s, left 0.5s;
    opacity: 0;
    animation: canvasFade 1.5s ease forwards;
    z-index: 999;
    cursor: grab;
}

canvas:active {
    cursor: grabbing;
}

@keyframes canvasFade {
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

#avatar-bars {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 4;
    padding: 40px;
    /* transform: translateY(20px); */
    transition: width 0.6s, height 0.7s, top 0.6s, left 0.6s, padding 0.5s,
        opacity 0.5s;
}

.App > main {
    padding-top: 100px;
}

@media screen and (max-width: 786px) {
    #avatar-bars {
        padding: 40px;
    }
}

@media screen and (max-width: 450px) {
    #avatar-bars {
        padding: 20px;
    }
}

*::-webkit-scrollbar {
    width: 8px;
}

*::-webkit-scrollbar-track {
    background: rgba(5, 5, 5, 0.2);
    border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    transition: background 0.3s;
}

*::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.6);
}
