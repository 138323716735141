nav {
    position: fixed;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    min-height: 100px;
    background-color: rgba(32, 32, 32, 0.9);
    backdrop-filter: blur(25px);

    padding: 0 20px;

    z-index: 3;
}

nav ul {
    display: flex;
    list-style: none;
}

nav ul li {
    cursor: pointer;
    font-size: 15px;
}

nav button {
    background: transparent;
    outline: none;
    border: none;
    margin: 0 4vw;
}

@media screen and (max-width: 768px) {
    nav ul li {
        font-size: 13px;
        padding: 0 2vw !important;
    }
}

@media screen and (max-width: 600px) {
    nav button {
        margin: 0 !important;
    }
}

@media screen and (max-width: 400px) {
    nav {
        padding: 0;
    }

    nav ul li {
        font-size: 12px;
        padding: 0 !important;
    }
}

@media screen and (max-width: 346px) {
    nav {
        padding: 0;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        font-size: 12px;
        padding: 5px 0 !important;
    }
}
