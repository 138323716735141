section#about {
    display: flex;
    align-items: center;
    min-height: 100vh;
    background-color: #202020;
    position: relative;
}

section#about > div {
    padding: 20px;
    border-radius: 20px;
    margin-left: 50vw;
}

#about-title {
    display: flex;
    flex-direction: column;
    color: #fff;
    z-index: 2;
    position: relative;
    word-break: auto-phrase;
}

#about-title p {
    opacity: 0.85;
    line-height: 175%;
    margin: 0;
}

#about-title p em {
    opacity: 0.5;
}

#about-title p span {
    font-size: 14px;
    background-color: #151515;
    padding: 5px 6px;
    margin: 4px;
    margin-left: 0;
    word-break: keep-all;
}

#about-title p span,
#about-title small ul span {
    transition: all 0.05s linear;
}

#about-title p span:hover,
#about-title small ul span:hover,
#about-title p span:active,
#about-title small ul span:active {
    box-shadow: -2px 0px 0px 0px #fff;
    transition: all 0.2s ease-in-out;
}

#about-title h1 {
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 2px;
    font-size: calc(12px + 3vw);
    margin: 0;
    opacity: 0.85;
}

#about-title small {
    display: flex;
    align-items: center;
    opacity: 0.7;
    color: rgba(255, 255, 255, 0.75);
    font-size: calc(14px + 0.25vw);
    margin-top: -1vw;
}

#about-title small ul {
    display: flex;
    padding: 0 5px;
}

#about-title small ul li {
    padding: 0 10px 0 0;
    font-size: calc(12px + 0.25vw);
}

#about-title small ul span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 4px;
    font-size: 3px;
    opacity: 0.6;
}

#about-title p a {
    color: #fff;
    opacity: 0.9;
    text-decoration: none;
}

#about-title p a > b {
    position: relative;
    user-select: none;
}

#about-title p a > b::before {
    content: "lukasz.frydrych@xsiadron.com";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #101010;
    font-size: 0%;
    opacity: 0;
    padding: 5px;

    transition: all 0.2s;
}

#about-title p a > b:hover::before {
    top: -35px;
    font-size: 80%;
    opacity: 1;
}

#build_projects {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
    #about-title p {
        font-size: 11px;
    }

    #about-title p span {
        font-size: 8px;
    }

    #about-title h1 {
        font-size: calc(8px + 2.5vw);
    }

    #about-title small {
        font-size: calc(10px + 0.25vw);
    }

    #about-title small ul li {
        padding-right: 5px;
        font-size: calc(8px + 0.3vw);
    }

    section#about > div {
        margin-left: 35vw;
    }

    section#about {
        min-height: 75vh;
    }
}

@media screen and (max-width: 368px) {
    section#about > div {
        margin-left: 0vw;
    }

    #build_projects_text_block {
        display: flex;
        flex-direction: column;
        margin-left: 35vw;
    }

    #build_projects {
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;
    }

    #about-title p span {
        padding: 2px 4px !important;
        margin: 1px !important;
    }
}

@media screen and (max-width: 268px) {
    #about-title p {
        font-size: 9px;
    }

    #about-title p span {
        font-size: 7px;
    }

    #about-title h1 {
        font-size: calc(6px + 2.5vw);
    }

    #about-title small {
        font-size: calc(8px + 0.25vw);
    }

    #about-title small ul li {
        font-size: calc(6px + 0.3vw);
    }

    #about-title p span {
        margin: 0 !important;
    }

    section#about > div {
        padding: 5px;
    }
}
